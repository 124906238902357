/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    extend: {
      transitionProperty: {
        height: 'height',
      },
      fontSize: {
        '3xs': '0.5rem',
        '2xs': '0.625rem',
      },
      flex: {
        1: '1 1 0%',
        2: '2 2 0%',
        3: '3 3 0%',
        4: '4 4 0%',
        5: '5 5 0%',
        6: '6 6 0%',
        7: '7 7 0%',
        8: '8 8 0%',
      },
      colors: {
        primary: '#3B3F44',
        secondary: '#D30B0B',
        warning: '#B45309',
        red: {
          50: '#FFF5F5',
          100: '#FED7D7',
          500: '#E53E3E',
          600: '#D30B0B',
          700: '#9B2C2C',
          800: '#822727',
        },
        gray: {
          10: '#F3F3F3',
          50: '#F8F9FA',
          75: '#F4F5F6',
          100: '#EBEDEF',
          400: '#BDC4CB',
          500: '#95A1AC',
          650: '#7C7C7D',
          700: '#646D74',
          800: '#55616D',
          900: '#3D3D3D',
        },
        pink: {
          50: '#FAE0E2',
          400: '#C03744',
          800: '#8C1823',
          900: '#3B3F44',
        },
        green: {
          50: '#F0FFF4',
          75: '#ECFDF5',
          100: '#C6F6D5',
          200: '#F0FFF4',
          300: '#CFF1DF',
          400: '#48BB78',
          500: '#38A169',
          600: '#059669',
          800: '#22543D',
          900: '#10B981',
          1000: '#047857',
        },
      },
    },
    transitionDuration: {
      75: '75ms',
      200: '200ms',
      300: '300ms',
      400: '400ms',
      500: '500ms',
      600: '600ms',
      700: '700ms',
      750: '750ms',
      1000: '1000ms',
      1500: '1500ms',
      2000: '2000ms',
      2500: '2500ms',
      3000: '3000ms',
      3500: '3500ms',
      4000: '4000ms',
      4500: '4500ms',
      5000: '5000ms',
    },
    spacing: {
      0: 0,
      px: 1,
      0.5: '2px',
      0.75: '3px',
      1: '4px',
      1.25: '5px',
      1.5: '6px',
      2: '8px',
      2.5: 10,
      3: 12,
      3.5: 14,
      4: 16,
      5: 20,
      6: 24,
      7: 28,
      8: 32,
      9: 36,
      10: 40,
      11: 44,
      12: 48,
      13: 52,
      13.25: 53,
      13.5: 54,
      14: 56,
      15: 60,
      15.25: 61,
      15.5: 62,
      16: 64,
      20: 80,
      22: 88,
      24: 96,
      28: 112,
      30: 120,
      32: 128,
      36: 144,
      40: 160,
      41: 164,
      42: 168,
      43: 172,
      44: 176,
      46: 184,
      48: 192,
      49: 196,
      50: 200,
      50.25: 201,
      50.5: 202,
      50.75: 203,
      51: 204,
      51.25: 205,
      51.5: 206,
      52: 208,
      53: 212,
      53.25: 213,
      53.5: 214,
      53.75: 215,
      54: 216,
      54.25: 217,
      54.5: 218,
      54.75: 219,
      55: 220,
      56: 224,
      57: 228,
      57.5: 230,
      57.75: 231,
      58: 232,
      59: 236,
      60: 240,
      61: 244,
      62: 248,
      63: 252,
      64: 256,
      64.5: 258,
      65: 260,
      66: 264,
      68: 272,
      69: 276,
      70: 280,
      72: 288,
      80: 320,
      96: 384,
      132: 528,
      192: 768,
      256: 1024,
    },
    zIndex: {
      60: '60',
      70: '70',
      80: '80',
      90: '90',
      100: '100',
    },
    lineHeight: {
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      4.5: '1.125rem',
      5: '1.25rem',
      5.5: '1.375rem',
      6: '1.5rem',
      6.5: '1.625rem',
      7: '1.75rem',
    },
  },
  plugins: [],
};
