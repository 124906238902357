import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type FlexProps = {
  className?: string;
  children?: string | React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Flex component to display children in a flex row.
 * - The children are displayed in a flex row with justify-start.
 * @param className - Additional classes to apply to the flex container
 * @param children - The children to display in the flex container
 * @returns
 */
const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ className = '', children, ...props }, ref) => {
    return (
      <div
        className={twMerge('flex flex-row justify-start', className)}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Flex.displayName = 'Flex';
export default Flex;
